<template>
  <v-card elevation="6" v-if="box">
    <v-card-title
      ><h2
        class="my-3"
        style="text-transform: uppercase; font-weight: normal"
        v-text="$tc('box')"
      ></h2>
      <v-btn
        icon
        style="position: absolute; right: 15px"
        @click="$emit('popup')"
      >
        <v-icon small class="ml-2" color="primary"> $edit </v-icon>
      </v-btn>
    </v-card-title>
    <v-divider> </v-divider>
    <v-simple-table class="pb-5">
      <tr>
        <td>{{ $t("first_name") }}</td>
        <td>{{ box.name }}</td>
      </tr>
      <tr v-if="this.$store.getters['auth/getSetting']('box_price') == 1">
        <td>{{ $t("boxes.price") }}</td>
        <td>{{ $n(box.price, "currency") || "-" }}</td>
      </tr>
      <tr v-if="this.$store.getters['auth/getSetting']('payment_box') == 1">
        <td>{{ $t("boxes.token_payment") }}</td>
        <td>
          {{ $n(box.token_payment, "currency") || "-" }}
        </td>
      </tr>
      <tr v-if="box.user">
        <td>{{ $t("boxes.tattooer_associate") }}</td>
        <td>{{ box.user.first_name }} {{ box.user.last_name }}</td>
      </tr>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "BoxCard",
  props: ["box"],
};
</script>
<style scoped lang="sass">
.v-data-table
  td
    padding: 3px 8px !important
  td:first-child
    color: var(--v-gris1-base)
  td:last-child
    overflow: hidden
    text-overflow: ellipsis
h2
  font-weight: normal
  font-size: 20px
</style>